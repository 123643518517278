import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { flatMap, noop } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import moment from "moment";
import { EmployeesApi } from 'lib/api-endpoints';
import { useNotificationContext } from 'state/context';
import { Form, FormActions } from 'components/forms';
import Drawer from 'components/drawer/drawer';
import DrawerTitle from 'components/drawer-title/drawer-title';
import EditEmployeeForm from 'pages/employee/edit-employee-form';
import FormContent from 'components/forms/form-container/form-content';

/**
 * Resets the form and runs custom methods on close
 *
 * @param {function} onClose The custom methods to run
 * @param {function} reset The form reset to default values
 *
 * @returns {void}
 */
const onDrawerClose = (onClose, reset) => {
    onClose();
    reset();
};

/**
 * Formats the employee values to show in the form inputs
 *
 * @param {object} employee The employee details
 *
 * @returns {object} The formatted employee default values
 */
const formattedDefaults = (employee) => {
    const [surname, forename] = employee?.name.split(", ");

    const currentTroncScheme = employee.current_tronc_scheme.length
        ? employee.current_tronc_scheme.map((scheme) => scheme.name).join(", ")
        : "No Tronc Schemes available";

    return {
        ...employee,
        email: employee?.emails?.home,
        startDate: employee?.start_date ? moment(employee.start_date).format('DD/MM/YYYY') : '',
        forename: forename.trim(),
        surname: surname.trim(),
        mobileNumber: employee?.phone_numbers?.mobile,
        'troncSchemeOptOut': employee?.is_opted_out_of_tronc_scheme,
        currentTroncScheme: currentTroncScheme ?? [],
        'jobRole': employee?.job_roles[0]?.job_role_id ?? "",
    };
};

/**
 * The employee edit drawer
 *
 * @param {bool} isOpen Is the form open
 * @param {func} onClose Function to execute on close of drawer
 * @param {object} employee The employee to edit
 * @param {array} jobRoles The company job roles
 * @param {bool} loading The loading flag
 *
 * @returns {React.Component} The drawer showing the employee edit form
 */
const EmployeeEditDrawer = ({ isOpen, onClose, employee, jobRoles, loading }) => {
    const { openNotification } = useNotificationContext();

    const jobRolesOptions = flatMap(jobRoles, (roles) => {
        if (roles.job_roles.length < 1) {
            return ({
                value: "",
                display: "",
            });
        }

        return flatMap(roles.job_roles, ((role) => {
            return ({
                value: role.id,
                display: role.job_title,
            });
        }));
    }).filter((roles) => roles.value !== "");

    const methods = useForm({
        mode: "onChange",
        defaultValues: !loading && formattedDefaults(employee),
    });

    useEffect(() => {
        methods.reset(!loading && formattedDefaults(employee));
    }, [employee, loading, methods]);

    /**
     * Submits the form data to the api in the correct format
     *
     * @param {string} forename Employee first name
     * @param {string} surname Employee surname
     * @param {string} email Employee home email
     * @param {boolean} troncSchemeOptOut The opt out status
     * @param {string} mobileNumber Employee mobile number
     * @param {string} jobRole Employee job role
     * @param {string} startDate Employee start date
     *
     * @returns {void}
     */
    const onSubmit = ({
        forename,
        surname,
        email,
        troncSchemeOptOut,
        mobileNumber,
        jobRole,
        startDate,
    }) => {
        let formattedFormData = {
            forename,
            surname,
            emails: {
                home: email !== "" ? email : null,
            },
            'phone_numbers': {
                mobile: mobileNumber !== "" ? mobileNumber : null,
            },
            'job_roles': jobRole !== "" ? [jobRole] : [],
            'is_opted_out_of_tronc_scheme': troncSchemeOptOut,
        };

        if (startDate) {
            // eslint-disable-next-line camelcase
            formattedFormData.start_date = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }

        EmployeesApi
            .editEmployee(employee.id, formattedFormData)
            .request
            .then(onSuccess);
    };

    /**
     * Success method to notify user, reset the form and close drawer
     *
     * returns {void}
     */
    const onSuccess = () => {
        openNotification({
            message: "Employee successfully updated",
            type: "success",
        });

        methods.reset();
        onClose();
    };

    return (
        <Drawer
            isOpen={isOpen}
            isLoading={loading}
            headerContent={<DrawerTitle>Edit Employee Details</DrawerTitle>}
            padding={false}
            onClose={() => onDrawerClose(onClose, methods.reset)}
            onOutsideClick={() => onDrawerClose(onClose, methods.reset)}
            ariaLabel="edit employee form drawer"
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormContent>
                        <EditEmployeeForm jobRolesOptions={jobRolesOptions} />
                    </FormContent>
                    <FormActions
                        onSubmit={methods.handleSubmit(onSubmit)}
                        submitText="Save"
                        disableSubmit={!methods.formState.isValid}
                        onCancel={() => onDrawerClose(onClose, methods.reset)}
                    />
                </Form>
            </FormProvider>
        </Drawer>
    );
};

EmployeeEditDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    employee: PropTypes.object,
    jobRoles: PropTypes.array,
    loading: PropTypes.bool,
};

EmployeeEditDrawer.defaultProps = {
    isOpen: false,
    onClose: noop,
    employee: null,
    jobRoles: [],
    loading: false,
};

export default EmployeeEditDrawer;
